import axios from "axios";
export default {
    getChatwootHistory(username, page = 1, pageSize = 50) {
        return axios({
            method: "GET",
            url: `/api/chatwoot-history/get-chat-history/${username}?page=${page}&pageSize=${pageSize}`,
            withCredentials: true,
        });
    },
    getChatwootHistoryByConversation(conversationId, page = 1, pageSize = 50) {
        return axios({
            method: "GET",
            url: `/api/chatwoot-history/get-chat-history-by-conversation/${conversationId}?page=${page}&pageSize=${pageSize}`,
            withCredentials: true,
        });
    },
    getConversation(username, page = 1, pageSize = 50) {
        return axios({
            method: "GET",
            url: `/api/chatwoot-history/get-chat-conversation/${username}?page=${page}&pageSize=${pageSize}`,
            withCredentials: true,
        });
    },
    getChatContacts(username, startDate, endDate, page = 1, pageSize = 50) {
        return axios({
            method: "GET",
            url: `/api/chatwoot-history/get-chat-contacts/?username=${username}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`,
            withCredentials: true,
        });
    },
    getConversationByContactId(contactId, page = 1, pageSize = 50) {
        return axios({
            method: "GET",
            url: `/api/chatwoot-history/get-chat-conversation-by-contact/${contactId}?page=${page}&pageSize=${pageSize}`,
            withCredentials: true,
        });
    },
};
