<template>
    <div class="d-flex flex-column direct-chat-primary">
        <div class="d-flex flex-fill row-eq-height">
            <div style="width: 320px; max-width: 320px; flex: 0 0 320px; " class="mr-3 d-flex flex-column"
                 v-loading="isLoadingConversation">
                <div class="flex-fill w-100 d-flex flex-column" style="border-right: 1px solid #e0e0e0;">
                    <div class="mr-2 mb-2 d-flex align-items-center">
                        <div class="flex-fill">
                            <!--<el-input
                                placeholder="Type something"
                                prefix-icon="el-icon-search">
                            </el-input>-->
                            <h5 class="mb-0"><strong class="text-primary">Conversations</strong></h5>
                        </div>
                        <div class="ml-2">
                            <el-button icon="el-icon-refresh" @click="refreshConversation" circle></el-button>
                        </div>
                    </div> 
                    <VuePerfectScrollbar class="flex-fill w-100 pr-0 mb-2"
                        v-if="conversationItems && conversationItems.length > 0"
                        id="chatConversationScroller"
                        :settings="{ suppressScrollX: true }"
                        style="height: 100px;">
                        <div class="p-0 w-100">
                            <ul class="nav nav-pills flex-column">
                                <li class="nav-item conversation-item" 
                                    v-for="conversationItem in conversationItems" 
                                    :key="conversationItem.id"
                                    v-bind:class="{ 'selected': conversationItemSelected.id === conversationItem.id }">
                                    <a href="javascript:void(0);" class="nav-link pl-2 pr-0"
                                       @click="changeConversation(conversationItem)">
                                        <div class="d-flex align-items-center">
                                            <div class="mr-2">
                                                <i class="el-icon-chat-round fa-2x"></i>
                                            </div>
                                            <div class="flex-fill">
                                                <div><strong class="text-nowrap">Conversation {{conversationItem.createdAt | pretyDateUtc}}</strong></div>
                                                <div style="font-size: 85%;">
                                                    <v-clamp autoresize :max-lines="1">{{conversationItem.lastMessage}}</v-clamp>
                                                </div>                                                
                                                <!--<div style="font-size: 80%; opacity: 0.6; font-style:italic;">
                                                    <i class="el-icon-time"></i> {{conversationItem.lastActivityAt | pretyDateUtc}}
                                                </div>-->
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </VuePerfectScrollbar>
                    <div v-if="conversationItems.length === 0" class="flex-fill w-100">
                        <div class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                            <div class="align-self-center text-center pl-3 pr-3">
                                <div class="mb-2"><i class="text-muted el-icon-chat-line-round fa-3x"></i></div>
                                <h5 class="mb-0"><strong class="text-muted">There is no conversation</strong></h5> 
                                <p class="text-muted">Conversation will show when customer make conversation!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-fill d-flex flex-column" 
                v-loading="isLoading">
                <div class="text-left mb-2 ml-2">
                    <el-button @click="refreshChatwootHistory()" type="text"
                               v-if="conversationItemSelected">
                        <i class="el-icon-refresh"></i> Refresh
                    </el-button>
                    <el-button @click="scrollToEnd" type="text" v-if="conversationItemSelected && messageItems.length > 0">
                        <i class="el-icon-bottom"></i> Scroll last message
                    </el-button>
                </div>
                <VuePerfectScrollbar class="flex-fill w-100 pr-3 mt-2 mb-3"
                                     v-if="targetUserItem && messageItems.length > 0"
                                     id="chatScroller"
                                     :settings="{ suppressScrollX: true }"
                                     style="height: 100px;">
                    <div class="chat-history-box w-100 mr-3">
                        <div class="mt-2 ml-2 d-flex flex-column w-100">
                            <div class="d-flex"
                                 v-bind:class="{ 'justify-content-start' : isContactMsg(messageItem), 'justify-content-end' : !isContactMsg(messageItem) }"
                                 v-for="messageItem in messageItems.filter(xItem => xItem.content && xItem.content !== null && xItem.content !== '')"
                                 :key="messageItem.id"
                                 :id="`messageItemId_${messageItem.id}`">
                                <div class="d-flex align-items-end"
                                     v-bind:class="{ 'justify-content-start' : isContactMsg(messageItem), 'justify-content-end' : !isContactMsg(messageItem) }">
                                    <div v-if="isContactMsg(messageItem)">
                                        <div v-if="!targetUserItem.profilePhoto.includes('http')">
                                            <img v-if="!targetUserItem.profilePhoto.includes('data:image')"
                                                 class="direct-chat-img" style="margin-bottom: 0.8rem;"
                                                 :src="`data:image/jpeg;base64,${targetUserItem.profilePhoto}`"
                                                 onerror="this.src='/img/avatars/male.png'"
                                                 :alt="targetUserItem.fullName">
                                            <img v-else
                                                 class="direct-chat-img" style="margin-bottom: 0.8rem;"
                                                 :src="targetUserItem.profilePhoto"
                                                 onerror="this.src='/img/avatars/male.png'"
                                                 :alt="targetUserItem.fullName">
                                        </div>
                                        <img v-else
                                             class="direct-chat-img" style="margin-bottom: 0.8rem;"
                                             :src="targetUserItem.profilePhoto"
                                             onerror="this.src='/img/avatars/male.png'"
                                             :alt="targetUserItem.fullName">
                                    </div>
                                    <div class="bubble"
                                         v-bind:class="{ 'bubble-left' : isContactMsg(messageItem), 'bubble-right' : !isContactMsg(messageItem) }">
                                        <div>{{messageItem.content}}</div>
                                        <div class="mt-1" style="font-size: 80%; opacity: 0.6; font-style:italic;">
                                            <i class="el-icon-time"></i> {{messageItem.createdAt | pretyDateUtc}}
                                        </div>
                                    </div>
                                    <div v-if="!isContactMsg(messageItem) && targetUserItem">
                                        <img class="direct-chat-img" style="margin-bottom: 0.8rem;" v-if="messageItem.agentEmail"
                                             :src="`/img/avatars/${messageItem.agentEmail}.jpg`"
                                             onerror="this.src='/img/avatar5.png'"
                                             :alt="staffsInfo && staffsInfo[targetUserItem.staffSupport] ? staffsInfo[targetUserItem.staffSupport].fullName : targetUserItem.staffSupport">

                                        <img class="direct-chat-img" style="margin-bottom: 0.8rem;" v-else
                                             src="/img/mappin-default.png"
                                             onerror="this.src='/img/avatar5.png'"
                                             alt="iRender system">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </VuePerfectScrollbar>
                <div v-if="targetUserItem && messageItems.length === 0"
                     class="flex-fill w-100">
                    <div class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                        <div class="align-self-center text-center">
                            <div class="mb-2"><img src="/static/image/no-job-icon.svg" style="opacity: 0.7;"></div>
                            <h4 class="mb-0"><strong class="text-muted">There is no message</strong></h4>
                            <p class="text-muted">Select a conversation to show message !</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import VClamp from 'vue-clamp';
    import baseComponent from "@/scripts/baseComponent";
    import chatwootHistoryApi from "@/api/common/chatwootHistoryApi";
    export default {
        extends: baseComponent,
        components: {
            VClamp
        },
        props: {
            detailUserItem: {
                type: Object,
                default() {
                    return null;
                },
            },
            contactItem: {
                type: Object,
                default() {
                    return null;
                },
            }
        },
        data() {
            return {
                isLoading: true,
                isLoadingConversation: true,
                isInited: false,
                isInitedConversation: false,
                currentPage: 1,
                currentPageConversation: 1,
                conversationItems: [],
                conversationItemSelected: null,
                messageItems: [],
            };
        },
        watch: {
            detailUserItem(newVal) {
                this.isInited = false;
                this.isInitedConversation = false;
                this.$set(this, 'messageItems', []);
                this.conversationItems = [];
                if (this.detailUserItem !== null) {
                    this.getConversation("REFRESH");
                }
            },
            contactItem(newVal) {
                this.isInited = false;
                this.isInitedConversation = false;
                this.$set(this, 'messageItems', []);
                this.conversationItems = [];
                if (this.contactItem !== null) {
                    this.getConversation("REFRESH");
                }
            },
        },
        computed: {
            ...mapState({
                staffsInfo: (state) => state.staffs.reduce(function (result, item, index) {
                    result[item.email] = item;
                    return result;
                }, {}),
            }),
            targetUserItem() {
                if (this.detailUserItem != null) {
                    return {
                        profilePhoto: this.detailUserItem.profilePhoto,
                        fullName: this.detailUserItem.fullName, 
                        staffSupport: this.detailUserItem.staffSupport,
                    }
                }
                if (this.contactItem != null) {
                    var host = window.location.protocol + "//" + window.location.host;
                    return {
                        profilePhoto: `${host}/img/avatars/male.png`,
                        fullName: this.contactItem.name, 
                        staffSupport: 'none_staff',
                    }
                }
                return null;
            }
        },
        mounted() {
            this.getConversation("REFRESH");
        },
        beforeDestroy() {
            $("#chatScroller").off("scroll");
            $("#chatConversationScroller").off("scroll");
        },
        methods: {
            isContactMsg(messageItem) {
                return messageItem.senderType !== null && messageItem.senderType === 'Contact';
            },
            refreshConversation(){
                this.$set(this, 'messageItems', []);
                this.currentPageConversation = 1;
                this.getConversation("REFRESH");
            },
            getConversation(targetAction = "LOAD_MORE") {
                if (this.detailUserItem || this.contactItem) {
                    this.isLoadingConversation = true;
                    let promiseLoad = null;
                    if (this.detailUserItem && this.detailUserItem != null) promiseLoad = chatwootHistoryApi.getConversation(this.detailUserItem.userName, this.currentPageConversation, 50);
                    else {
                        if (this.contactItem && this.contactItem != null) promiseLoad = chatwootHistoryApi.getConversationByContactId(this.contactItem.id, this.currentPageConversation, 50);
                    } 
                    promiseLoad
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                //console.log("getConversation", response.data.data);
                                if (response.data.data.length > 0) {
                                    switch (targetAction) {
                                        case "LOAD_MORE":
                                            this.$set(this, 'conversationItems', (response.data.data).concat(this.conversationItems).map(xItem => {
                                                return {
                                                    ...xItem,
                                                    additionalAttributesData: xItem.additionalAttributes ? JSON.parse(xItem.additionalAttributes) : {}
                                                }
                                            }));
                                            break;
                                        case "REFRESH":
                                            this.$set(this, 'conversationItems', (response.data.data).map(xItem => {
                                                return {
                                                    ...xItem,
                                                    additionalAttributesData: xItem.additionalAttributes ? JSON.parse(xItem.additionalAttributes) : {}
                                                }
                                            }));
                                            if (this.conversationItems.length > 0) {
                                                this.conversationItemSelected = this.conversationItems[0];
                                                this.getChatwootHistoryByConversation(this.conversationItemSelected.id, "REFRESH");
                                            }
                                            this.$nextTick(() => {
                                                if (!this.isInitedConversation) {
                                                    this.isInitedConversation = true;
                                                    $("#chatConversationScroller").on("scroll", () => {
                                                        let scrollerHeight = $("#chatConversationScroller").prop("scrollHeight");
                                                        if ($("#chatConversationScroller").scrollTop() === scrollerHeight) {
                                                            this.loadOlderConversation();
                                                        }
                                                    });
                                                }
                                            });
                                            break;
                                    }
                                }
                                else {
                                    if (this.currentPageConversation > 1) this.currentPageConversation = this.currentPageConversation - 1;
                                    this.isLoading = false;
                                }
                                this.isLoadingConversation = false;
                            } else {
                                if (response.data.message !== null && response.data.message !== "") {
                                    console.error(response.data.message || this.$lang.common.error);
                                }
                                this.isLoadingConversation = false;
                                this.isLoading = false;
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.isLoadingConversation = false;
                        });
                }
                else {                    
                    this.isLoadingConversation = false;
                    this.isLoading = false;
                }
            },
            getChatwootHistoryByConversation(conversationId, targetAction = "LOAD_MORE") {
                if (this.detailUserItem || this.contactItem) {
                    this.isLoading = true;
                    chatwootHistoryApi.getChatwootHistoryByConversation(conversationId, this.currentPage, 50)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                //console.log("getChatwootHistoryByConversation", response.data.data);
                                if (response.data.data.length > 0) {
                                    let firstId = null;
                                    if (this.messageItems.length > 0) firstId = this.messageItems[0].id;
                                    switch (targetAction) {
                                        case "LOAD_MORE":
                                            this.$set(this, 'messageItems', (response.data.data.reverse()).concat(this.messageItems));
                                            if (firstId !== null) {
                                                this.$nextTick(() => {
                                                    $("#chatScroller").scrollTop($(`#messageItemId_${firstId}`).offset().top);
                                                });
                                            }
                                            break;
                                        case "REFRESH":
                                            this.$set(this, 'messageItems', (response.data.data.reverse()));
                                            this.$nextTick(() => {
                                                $("#chatScroller").animate({
                                                    scrollTop: $("#chatScroller").prop("scrollHeight")
                                                }, 100);
                                                if (!this.isInited) {
                                                    this.isInited = true;
                                                    $("#chatScroller").on("scroll", () => {
                                                        if ($("#chatScroller").scrollTop() === 0) {
                                                            this.loadOlder();
                                                        }
                                                    });
                                                }
                                            });
                                            break;
                                    }
                                }
                                else {
                                    if (this.currentPage > 1) this.currentPage = this.currentPage - 1;
                                }
                                this.isLoading = false;
                            } else {
                                if (response.data.message !== null && response.data.message !== "") {
                                    console.error(response.data.message || this.$lang.common.error);
                                }
                                this.isLoading = false;
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.isLoading = false;
                        });
                }
                else {                    
                    this.isLoadingConversation = false;
                    this.isLoading = false;
                }
            },
            refreshChatwootHistory() {     
                this.currentPage = 1;           
                this.getChatwootHistoryByConversation(this.conversationItemSelected.id, "REFRESH");
            },
            loadOlder() {
                this.currentPage = this.currentPage + 1;
                this.getChatwootHistoryByConversation(this.conversationItemSelected.id, "LOAD_MORE");
            },
            loadOlderConversation() {
                this.currentPageConversation = this.currentPageConversation + 1;
                this.getConversation("LOAD_MORE");
            },
            scrollToEnd() {
                const chatScroller = $("#chatScroller");
                chatScroller.animate({
                    scrollTop: chatScroller.prop("scrollHeight")
                }, 100);
            },            
            changeConversation(conversationItem) { 
                this.$set(this, 'messageItems', []);  
                $("#chatScroller").off("scroll");
                this.isInited = false;   
                this.conversationItemSelected = conversationItem;
                this.currentPage = 1;           
                this.getChatwootHistoryByConversation(this.conversationItemSelected.id, "REFRESH");
            },
        }
    }
</script>

<style scoped>

    .direct-chat-primary .right > div > .direct-chat-text {
        background-color: #1257f3;
        border-color: #1257f3;
        color: #fff;
    }

        .direct-chat-primary .right > div > .direct-chat-text::after,
        .direct-chat-primary .right > div > .direct-chat-text::before {
            border-left-color: #1257f3;
        }

    .chat-history-box::v-deep > .el-card__body {
        display: flex !important;
        flex-direction: column !important;
        flex: 1 1 auto !important;
    }


    .chat-history-box::v-deep .bubble {
        --r: 20px; /* the radius */
        --t: 20px; /* the size of the tail */
        margin-bottom: 0.8rem;
        max-width: 80%;
        padding: calc(2*var(--r)/3);
        -webkit-mask: radial-gradient(var(--t) at var(--_d) 0,#0000 98%,#000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat, conic-gradient(at var(--r) var(--r),#000 75%,#0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box, radial-gradient(closest-side at 50% 50%,#000 98%,#0000 101%) 0 0/var(--r) var(--r) space padding-box;
    }

    .chat-history-box::v-deep .bubble-left {
        --_d: 0%;
        border-left: var(--t) solid #0000;
        margin-right: var(--t);
        place-self: start;
        color: #fff;
        background: linear-gradient(135deg,#42a5f5,#1384C5) border-box;
    }

    .chat-history-box::v-deep .bubble-right {
        --_d: 100%;
        border-right: var(--t) solid #0000;
        margin-left: var(--t);
        place-self: end;
        color: #000;
        background: linear-gradient(135deg,#bbbbbb,#cecece) border-box;
    }

    .nav-item.conversation-item:hover,
    .nav-item.conversation-item.selected {
        background-color: #d1e6fc;
        color: #007bff;
    }

    .nav-item.conversation-item.selected a {
        color: #007bff !important;
    }
</style>